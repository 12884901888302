import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'
import LocationHelper from "../helpers/LocationHelper";
import axios from "axios";
import AnalyticsAPI from "../api/AnalyticsAPI";

const leftImg = require('./images/image_side_left.svg');
const rightImg = require('./images/image_side_right.svg');

const leftFig = require('./images/p_image.png');
const rightFig = require('./images/knight_image.png');

const API_ENDPOINT = 'https://api.cloud1.shagi.online';

const ie = a => (a == undefined || `${a}`.replace(/ /g, '') == '');

const getErrorMessage = (d) => {
    // let {name, email, phone} = d;
    let {name, phone} = d;
    if (ie(name) || name.length < 2) {
        return `Пожалуйста, укажите имя`;
    }
    if (ie(phone) || phone.length < 5) {
        return `Пожалуйста, укажите корректный телефон`;
    }
    // if (ie(email) || validateEmail(email) == false) {
    //     return `Пожалуйста, укажите корректный email`;
    // }
    return undefined;
}

const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export default function IraFormSection(props) {

    const {
        hasHeading = true,
        buttonName = 'Жду звонка!',

        googleActionName = 'Send_Form',
        yandexActionName = 'Send_Form',


        firstHeading = 'Запись на пробное занятие!',
        secondHeading = 'Оставьте заявку, мы Вам перезвоним и расскажем подробнее.'


    } = props;

    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');

    const [error, setError] = useState(undefined);
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);

    let isBadPhone = !(`${phone}`.length < `+7 (985) 436 37 04`.length && `${phone}`.length >= `9854363704`.length);
    isBadPhone = (isBadPhone || !re.test(phone));

    return (
        <OuterWrapper id={'new_lead_form'}>
            <Wrapper>

                <CenterSide>

                    <WhiteBox>

                        <Heading>
                            {firstHeading}
                        </Heading>


                        {sent == true ? null :
                            <SubHeading>
                                {secondHeading}
                            </SubHeading>
                        }

                        {sent == true ? null :
                            <FieldsPlaceholder>

                                <Input
                                    id={'new_lead_form_name_input'}
                                    value={name}
                                    placeholder={'Ваше имя'}
                                    onChange={evt => {
                                        setName(evt.target.value);
                                        setError(undefined);
                                    }}
                                />

                                <Input
                                    value={phone}
                                    pattern={''}
                                    placeholder={'Телефон'}
                                    onChange={evt => {
                                        setPhone(evt.target.value);
                                        setError(undefined);
                                    }}
                                />


                                {sent == true ? null :
                                    <Button onClick={async () => {
                                        let msg = getErrorMessage({phone, name});

                                        if (isBadPhone == true) {
                                            setError('Некорректно введен телефон');
                                            return;
                                        }

                                        if (msg != undefined) {
                                            setError(msg);
                                            return;
                                        }

                                        let url_ = ``;
                                        let cookieString = '';
                                        try {
                                            url_ = window.location.href;
                                        } catch (exc1) {

                                        }
                                        try {
                                            cookieString = document.cookie;
                                        } catch (exc1) {

                                        }

                                        setLoading(true);
                                        await axios.post(`${API_ENDPOINT}/lead`, {
                                            phone,
                                            name,
                                            url: url_,
                                            email: '',
                                            cookieString: cookieString
                                        }, {
                                            params: {
                                                spb: (window.location.href.indexOf('spb') > -1) ? 'yes' : 'no'
                                            }
                                        });
                                        setLoading(false);
                                        setSent(true);
                                        try {
                                            AnalyticsAPI.pixelTrackLead();
                                            // AnalyticsAPI.googleTrackEvent('button', googleActionName);
                                            AnalyticsAPI.googleTrackEvent('button', 'waiting_for_a_call');
                                            AnalyticsAPI.reachGoal('button_waiting_for_a_call');
                                            // AnalyticsAPI.googleTrackEvent('button', 'Send_Form');

                                        } catch (e) {

                                        }
                                    }}>
                                        {buttonName}
                                    </Button>
                                }

                            </FieldsPlaceholder>
                        }

                        <SubTextPlaceholder>
                            {sent == true ? null :
                                <div>
                                    Принимаю
                                    <AccLink target={'_blank'} style={{marginRight: 5, marginLeft: 5}}
                                             href={`/conf.pdf`}>
                                        Политику обработки персональных данных
                                    </AccLink>
                                    и выражаю согласие на обработку персональных данных. {' '}
                                </div>
                            }

                            {sent == false ? null :
                                <SentPlaceholder>
                                    {`Спасибо!`}
                                    <br/>
                                    {`Мы с Вами свяжемся по указанным контактам`}
                                    <br/>
                                    {`${phone}`}
                                    <br/>
                                    <br/>
                                    {/*+7 903 559-44-74*/}
                                    <WhatsLink target={'_blank'}
                                               href={`https://api.whatsapp.com/send?phone=${LocationHelper.getWhatsapPhone().replace('+', '')}&text=Добрый день!`}>
                                        Написать нам в Вотсап
                                    </WhatsLink>

                                </SentPlaceholder>
                            }

                            {error == undefined ? null :
                                <SentPlaceholder style={{color: 'red'}}>
                                    {error}
                                </SentPlaceholder>
                            }

                        </SubTextPlaceholder>

                    </WhiteBox>

                </CenterSide>

                <LeftSide>
                    <LeftImg src={leftImg}/>
                </LeftSide>
                <RightSide>
                    <RightImg src={rightImg}/>
                </RightSide>

                <LeftFigureImage src={leftFig}/>
                <RightFigureImage src={rightFig}/>

            </Wrapper>
        </OuterWrapper>
    );
}

const WhatsLink = styled.a`
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 1000px;
  color: white;
  background: #1ebea5;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  :hover {
    opacity: 0.8;
    color: white;
  }
`;

const AccLink = styled.a`
  margin-left: 5px;
`;


const SentPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  text-align: center;
`;

const LeftFigureImage = styled.img`
  height: 180px;
  position: absolute;
  left: 10px;
  z-index: 30;
  top: 90px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const RightFigureImage = styled.img`
  height: 130px;
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 30;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const SubTextPlaceholder = styled.div`
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  margin: 0 auto;
  width: 720px;
  box-sizing: border-box;
  @media (max-width: 760px) {
    width: 100%;
    box-sizing: border-box;
  }
`;


const breakWidth = 1100;

const Button = styled.div`
  height: 46px;
  padding-left: 50px;
  padding-right: 50px;
  color: black;
  font-size: 20px;
  line-height: 22px;
  background: #FFA519;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  cursor: pointer;

  @media (max-width: 720px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  :hover {
    opacity: 0.75;
  }
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 2px solid #A5A5A5;
  box-sizing: border-box;
  border-radius: 50px;
  outline: none;

  font-size: 16px;

  margin-right: 20px;

  height: 46px;
  padding-left: 20px;

  flex: 1;

  @media (max-width: 720px) {
    display: block;
    margin-bottom: 20px;
    margin-right: 0px;
    width: 100%;
  }

  :focus {
    border-color: blue;
  }
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  color: #FF624F;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 720px) {
    font-size: 24px;
  }
`;

const SubHeading = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  opacity: 0.7;
  box-sizing: border-box;
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: 720px) {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const FieldsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 800px;
  margin: 0 auto;

  margin-top: 30px;
  margin-bottom: 30px;

  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 720px) {
    width: 100%;
    display: block;
  }

`;


const OuterWrapper = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  min-height: 320px;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const WhiteBox = styled.div`
  position: relative;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-sizing: border-box;
  @media (max-width: 720px) {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const LeftImg = styled.img`
  height: 500px;

  @media (max-width: 800px) {
    display: none;
  }

`;

const RightImg = styled.img`
  height: 320px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const LeftSide = styled.div`
  position: absolute;
  left: -200px;
  top: -100px;
  bottom: 0px;
  z-index: 3;
`;

const RightSide = styled.div`
  right: -100px;
  top: 40px;
  bottom: 0px;
  position: absolute;
  z-index: 3;
`;

const CenterSide = styled.div`
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 20;
`;
