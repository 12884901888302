import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import './style.css'

const ernImg = require('./images/ernesto_opt.jpg')
const tileImg = require('./images/blue_tile.svg')
const hImg = require('./images/head.svg')

const ITEMS = [
    {
        url: 'https://ruchess.ru/',
        label: 'Федерация Шахмат России',
        image: require('./images/ruche.jpg')

    }
]

export default function PartnersSection(props) {


    return (
        <Wrapper>

            <Heading>
                Партнёры
            </Heading>

            <InnerPlaceholder className={'section-about__body'}>

                {ITEMS.map((a, i) => {
                    return (
                        <PartnerItem key={i} onClick={() => {
                            let win = window.open(a.url, '_blank');
                            win.focus();
                        }}>
                            <PImage src={a.image}/>
                            <PLabel>
                                {a.label}
                            </PLabel>
                        </PartnerItem>
                    )
                })}

            </InnerPlaceholder>


        </Wrapper>
    );
}

const breakWidth = 1100;

const PartnerItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const PHead = styled.div`
    
`;

const PLabel = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

const PImage = styled.img`
    height: 120px;
`;

const Heading = styled.h2`
     font-size: 35px;
     text-align: center;
     margin-bottom: 20px;
`;

const InnerPlaceholder = styled.div`
    width: ${breakWidth}px;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media(max-width: ${breakWidth}px){
      width: 100%;
    }
`;

const Wrapper = styled.div`
    width: 100vw;
    box-sizing: border-box;
    background: white;
    padding-top: 60px;
    padding-bottom: 60px;
    
`;
