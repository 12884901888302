import React from "react"
import {Link} from "gatsby"

import styled from 'styled-components'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import TopSection from "../components/sections/TopSection";
import NYTopSection from "../components/sections/NYTopSection";
import AboutSection from "../components/sections/AboutSection";
import BenefitsSection from "../components/sections/BenefitsSection";
import InTimeSection from "../components/sections/InTimeSection";
import ErnSection from "../components/sections/ErnSection";
import BounceSection from "../components/sections/BounceSection";
import VideoSection from "../components/sections/VideoSection";
import TrainersSection from "../components/sections/TrainersSection";
import WebinarSection from "../components/sections/WebinarsSection";
import FooterSection from "../components/sections/FooterSection";
import TestimonialsSection from "../components/sections/TestimonialsSection";
import SubTopSection from "../components/sections/SubTopSection";
import PartnersSection from "../components/sections/PartnersSection";
import IraFormSection from "../components/sections/IraFormSection";

const IndexPage = () => (
    <Layout>
        <SEO title="ШАГИ"/>
        <Wrapper>

            {/*<NYTopSection/>*/}

            <TopSection/>

            <div style={{marginTop: 120, marginBottom: 120}} >
                <IraFormSection
                    firstHeading={'Шахматы развивают мышление, логику и концентрацию внимания у ребенка'}
                    secondHeading={'Оставьте заявку на пробный урок, и мы поможем вашему ребенку добиваться успеха в любых сферах'}
                />
            </div>

            <SubTopSection/>

            <NyImagePlaceholder style={{display: 'none'}} target={'_blank'}
                                href={`https://tournament.shagi.online/?utm_source=landing`}>
                <NyImage src={require('../components/sections/images/ny_bban2.png')}/>
                <NyImage2 src={require('../components/sections/images/vv_dd2.png')}/>
            </NyImagePlaceholder>

            <BounceSection/>

            <AboutSection/>

            <ErnSection/>

            <div style={{marginTop: 120, marginBottom: 120}} >
                <IraFormSection
                    firstHeading={'Тренируйтесь только у лучших!'}
                    secondHeading={'Оставьте заявку и сыграйте партию с международным гроссмейстером'}
                />
            </div>

            <TrainersSection/>

            <VideoSection/>

            {/*<BounceSection/>*/}

            <InTimeSection/>

            {/*<MobInvisibleDiv>*/}
            {/*<WebinarSection/>*/}
            {/*</MobInvisibleDiv>*/}

            <div style={{marginTop: 120, marginBottom: 120}} >
                <IraFormSection
                    firstHeading={'Оставьте заявку, и мы поможем вашему ребенку стать чемпионом'}
                    secondHeading={''}
                />

            </div>

            <TestimonialsSection/>

            {/*<BenefitsSection/>*/}

            <IraFormSection/>

            <PartnersSection/>

            <FooterSection/>


        </Wrapper>

        <div dangerouslySetInnerHTML={{__html: `

<!-- Yandex.Metrika counter -->
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(65403928, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/65403928" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
<!-- /Yandex.Metrika counter -->

            <script>
            
        (function(w,d,u){
                var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn-ru.bitrix24.ru/b18523028/crm/site_button/loader_2_5lsqtc.js');
</script>

<script>
(function(w, d, s, h, id) {
    w.roistatProjectId = id; w.roistatHost = h;
    var p = d.location.protocol == "https:" ? "https://" : "http://";
    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', '7c6439476f0c659887e5bb6cf699db73');
</script>

<!-- BEGIN WHATSAPP INTEGRATION WITH ROISTAT -->
<script type="bogus" class="js-whatsapp-message-container">Здравствуйте! Расскажите подробнее о занятиях в академии. {roistat_visit}</script>
<script>
    (function() {
        if (window.roistat !== undefined) {
            handler();
        } else {
            var pastCallback = typeof window.onRoistatAllModulesLoaded === "function" ? window.onRoistatAllModulesLoaded : null;
            window.onRoistatAllModulesLoaded = function () {
                if (pastCallback !== null) {
                    pastCallback();
                }
                handler();
            };
        }

        function handler() {
            function init() {
                appendMessageToLinks();

                var delays = [1000, 5000, 15000];
                setTimeout(function func(i) {
                    if (i === undefined) {
                        i = 0;
                    }
                    appendMessageToLinks();
                    i++;
                    if (typeof delays[i] !== 'undefined') {
                        setTimeout(func, delays[i], i);
                    }
                }, delays[0]);
            }

            function replaceQueryParam(url, param, value) {
                var explodedUrl = url.split('?');
                var baseUrl = explodedUrl[0] || '';
                var query = '?' + (explodedUrl[1] || '');
                var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
                var queryWithoutParameter = query.replace(regex, "$1").replace(/&$/, '');
                return baseUrl + (queryWithoutParameter.length > 2 ? queryWithoutParameter  + '&' : '?') + (value ? param + "=" + value : '');
            }

            function appendMessageToLinks() {
                var message = document.querySelector('.js-whatsapp-message-container').text;
                var text = message.replace(/{roistat_visit}/g, window.roistatGetCookie('roistat_visit'));
                text = encodeURI(text);
                var linkElements = document.querySelectorAll('[href*="//wa.me"], [href*="//api.whatsapp.com/send"], [href*="//web.whatsapp.com/send"], [href^="whatsapp://send"]');
                for (var elementKey in linkElements) {
                    if (linkElements.hasOwnProperty(elementKey)) {
                        var element = linkElements[elementKey];
                        element.href = replaceQueryParam(element.href, 'text', text);
                    }
                }
            }
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', init);
            } else {
                init();
            }
        };
    })();
</script>
<!-- END WHATSAPP INTEGRATION WITH ROISTAT -->

        `}} >

        </div>

    </Layout>
)

export default IndexPage

const NyImagePlaceholder = styled.a`
    margin-bottom: 80px;
    display: block;
    @media(max-width: 720px){
      margin-bottom: 20px;
    }
`;

const NyImage = styled.img`
    width: 1020px;
    margin: 0 auto;
    display: block;
    @media(max-width: 1020px){
      width: 100%;
    }
    @media(max-width: 720px){
      display: none;
    }
`;

const NyImage2 = styled.img`
    width: 100%;
    margin: 0 auto;
    display: none;
    @media(max-width: 720px){
      display: block;
    }
`;

const MobInvisibleDiv = styled.div`
    @media(max-width: 812px){
      display: none;
    }
`;

const Wrapper = styled.div`
    //font-family: Avenir Next Cyr;
    font-family: "Open Sans",sans-serif;
    overflow-x: hidden !important;
`;
